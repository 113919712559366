import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr"; // or from "@microsoft/signalr" if you are using a new library
import { environment } from "environments/environment";
import { first, map, take } from "rxjs/operators";
import { NotificationDto } from "../models/notificationDto.model";
import { ResultResponse } from "../models/utils.model";
import { LocalStoreService } from "./local-store.service";
import { SignalRNotificationObserverManagerService } from "./SignalRNotificationObserverManagerService";
// import { ChartModel } from '../_interfaces/chartmodel.model';
@Injectable({
  providedIn: "root",
})
export class SignalRNotificationService {
  public data: ResultResponse<NotificationDto[]>;
  // public isConnected: boolean = false;
  public hubConnection: signalR.HubConnection;
  constructor(
    public ls: LocalStoreService,
    private SignalRObserver: SignalRNotificationObserverManagerService
  ) {
    this.manageConnection();
  }
  // public startConnection = () => {
  //   let connectionId = this.hubConnection?.connectionId;

  //   if (connectionId != undefined) {
  //     return;
  //   }

  //   // if (this.hubConnection.state != signalR.HubConnectionState.Disconnecting) {
  //   //   return;
  //   // }

  //   let url = `${environment.hubUrl}notify`;
  //   const token = this.ls.getItem("JWT_TOKEN");

  //   this.hubConnection = new signalR.HubConnectionBuilder()
  //     .withUrl(
  //       url,
  //       //   , {
  //       //   skipNegotiation: true,
  //       //   transport: signalR.HttpTransportType.WebSockets
  //       // },
  //       { accessTokenFactory: () => token }
  //     )
  //     .withAutomaticReconnect()
  //     .build();

  //   this.hubConnection.start();
  // };
  public manageConnection() {
    let _this = this;
    this.SignalRObserver.connectionStartRequestObserver$
      .pipe(first())
      .subscribe((p) => {
       
        let connectionId = _this.hubConnection?.connectionId;

        if (connectionId != undefined) {
          return;
        }

        // if (this.hubConnection.state != signalR.HubConnectionState.Disconnecting) {
        //   return;
        // }

        let url = `${environment.hubUrl}notify`;
        const token = _this.ls.getItem("JWT_TOKEN");
        
        if(token ==undefined || token ==null){
          return
        }

        _this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(
            url,
            //   , {
            //   skipNegotiation: true,
            //   transport: signalR.HttpTransportType.WebSockets
            // },
            { accessTokenFactory: () => token }
          )
          .withAutomaticReconnect()
          .build();

        _this.hubConnection
          .start()
          .then(() => {
            //this.isConnected = true;
            console.log("Connection started");
            var x = _this.hubConnection.state;
            _this.SignalRObserver.iGothubConnectionStared(_this.hubConnection);
          })
          .catch((err) => {
            // this.isConnected = false;
            console.log("Error while starting connection: " + err);
          });
      });
  }
  public startConnection() {
    let _this = this;

    

    let url = `${environment.hubUrl}notify`;
    const token = _this.ls.getItem("JWT_TOKEN");
    
    if(token ==undefined || token ==null){
      return
    }

    _this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(
        url,
        //   , {
        //   skipNegotiation: true,
        //   transport: signalR.HttpTransportType.WebSockets
        // },
        { accessTokenFactory: () => token }
      )
      .withAutomaticReconnect()
      .build();

    _this.hubConnection
      .start()
      .then(() => {
        //this.isConnected = true;
        console.log("Connection started");
        var x = _this.hubConnection.state;
        _this.SignalRObserver.iGothubConnectionStared(_this.hubConnection);
      })
      .catch((err) => {
        // this.isConnected = false;
        console.log("Error while starting connection: " + err);
      });
  }
}
