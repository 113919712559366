<div class="header-topnav mat-elevation-z2" color="primary">
  <div class="container">
    <div class="topnav">
      <!-- App Logo -->
      <div class="topbar-branding">
        <img  width="250px" src="./assets/images/farmenzologo-white.png" alt="" class="app-logo" />
      </div>
      <!-- <span fxFlex></span> -->
      <ul class="menu" *ngIf="!layoutConf.isMobile">
        <li *ngFor="let item of menuItems; let i = index">
          <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
            <a
              matRipple
              routerLink="/{{ item.state }}"
              *ngIf="item.type === 'link'"
            >
              <mat-icon>{{ item.icon }}</mat-icon>
              {{ item.name | translate }}
            </a>
            <div *ngIf="item.type === 'dropDown'">
              <label matRipple for="drop-{{ i }}" class="toggle">
                <mat-icon>{{ item.icon }}</mat-icon> {{ item.name | translate }}
              </label>
              <a matRipple>
                <mat-icon>{{ item.icon }}</mat-icon> {{ item.name | translate }}
              </a>
              <input type="checkbox" id="drop-{{ i }}" />
              <ul>
                <li
                  *ngFor="let itemLvL2 of item.sub; let j = index"
                  routerLinkActive="open"
                >
                  <a
                    matRipple
                    routerLink="{{ item.state ? '/' + item.state : '' }}/{{
                      itemLvL2.state
                    }}"
                    *ngIf="itemLvL2.type !== 'dropDown'"
                  >
                    <mat-icon *ngIf="itemLvL2.icon">{{
                      itemLvL2.icon
                    }}</mat-icon>
                    {{ itemLvL2.name | translate }}
                  </a>

                  <div *ngIf="itemLvL2.type === 'dropDown'">
                    <label matRipple for="drop-{{ i }}{{ j }}" class="toggle">{{
                      itemLvL2.name | translate
                    }}</label>
                    <a matRipple>
                      <mat-icon *ngIf="itemLvL2.icon">{{
                        itemLvL2.icon
                      }}</mat-icon>
                      {{ itemLvL2.name | translate }}
                    </a>
                    <input type="checkbox" id="drop-{{ i }}{{ j }}" />
                    <!-- Level 3 -->
                    <ul>
                      <li
                        *ngFor="let itemLvL3 of itemLvL2.sub"
                        routerLinkActive="open"
                      >
                        <a
                          matRipple
                          routerLink="{{ item.state ? '/' + item.state : '' }}{{
                            itemLvL2.state ? '/' + itemLvL2.state : ''
                          }}/{{ itemLvL3.state }}"
                        >
                          <mat-icon *ngIf="itemLvL3.icon">{{
                            itemLvL3.icon
                          }}</mat-icon>
                          {{ itemLvL3.name | translate }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>

      <span fxFlex></span>
      <!-- End Navigation -->

     
      <app-chatNotifications-warning
        [chatNotificPanel]="chatNotificationPanel"
        (showChatNotificationdetailsEmitter)="
          GetshowChatNotificationdetails($event)
        "
        *ngIf="isLogged"
      ></app-chatNotifications-warning>

      <!-- Notification toggle button -->
      <app-notifications-warning
        [notificPanel]="notificPanel"
        (showChatNotificationdetailsEmitter)="
          GetshowChatNotificationdetails($event)
        "
        *ngIf="isLogged"
      ></app-notifications-warning>

      <!-- Notification toggle button -->

      <!-- Top left user menu -->
      <div
        *ngIf="!isLogged"
        style="padding-right: 20px"
        (click)="openSignIN()"
        class="menuBtn"
      >
        Sign In
      </div>

      <button
        mat-icon-button
        *ngIf="isLogged"
        [matMenuTriggerFor]="accountMenu"
        class="topbar-button-right mr-1 img-button"
      >
        
        <img
              [src]="userProfileImgURL"
              class="mat-elevation-z1"
              alt=""
            />
      </button>
      <mat-menu #accountMenu="matMenu">
        <!-- <button mat-menu-item [routerLink]="['/profile/overview']">
          <mat-icon>account_box</mat-icon>
          <span>Profile</span>
        </button> -->
        <button mat-menu-item (click)="getRouteURL('/Profile/UserProfile')">
          <mat-icon>account_circle</mat-icon>
          <span>{{ user?.firstName }} {{ user?.lastName }}</span>
        </button>

        <button mat-menu-item (click)="getRouteURL('/Profile/UserAddressList')">
          <mat-icon>pin_drop</mat-icon>
          <span>{{ "myAddress" | translate }}</span>
        </button>

        <button
          mat-menu-item
          (click)="getRouteURL('/Profile/UserContactInfoList')"
        >
          <mat-icon> perm_phone_msg</mat-icon>
          <span>{{ "myContactInfo" | translate }}</span>
        </button>
        <!-- <button mat-menu-item>
          <mat-icon>notifications_off</mat-icon>
          <span>Disable alerts</span>
        </button> -->
        <button mat-menu-item (click)="signout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ "SIGNOUT" | translate }}</span>
        </button>
      </mat-menu>
      <!-- Mobile screen menu toggle -->
      <button
        mat-icon-button
        class="mr-1"
        (click)="toggleSidenav()"
        *ngIf="layoutConf.isMobile"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</div>
