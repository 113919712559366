import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedMaterialModule } from "../shared-material.module";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SearchModule } from "../search/search.module";
import { SharedPipesModule } from "../pipes/shared-pipes.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedDirectivesModule } from "../directives/shared-directives.module";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./header-side/header-side.component";
import { SidebarSideComponent } from "./sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./header-top/header-top.component";
import { SidebarTopComponent } from "./sidebar-top/sidebar-top.component";

// ONLY FOR DEMO
import { CustomizerComponent } from "./customizer/customizer.component";

// ALWAYS REQUIRED

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { GuestLayoutComponent } from "./layouts/guest-layout/guest-layout.component";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { AppComfirmComponent } from "../services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "../services/app-loader/app-loader.component";
import { ButtonLoadingComponent } from "./button-loading/button-loading.component";
import {
  EgretSidebarComponent,
  EgretSidebarTogglerDirective,
} from "./egret-sidebar/egret-sidebar.component";
import { BottomSheetShareComponent } from "./bottom-sheet-share/bottom-sheet-share.component";
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { MapLayoutComponent } from "./layouts/Map-layout/map-layout.component";
import { TopbarLayoutComponent } from "./layouts/topbar-layout/topbar-layout.component";
import { ThemeService } from "../services/theme.service";
import { ProfileService } from "../services/Profile/profile.service";
import { SharedService } from "../services/shared.service";
import { NotificationsWarningComponent } from "./notifications/notifications-warning/notifications-warning.component";
import { notificationsDetailsComponent } from "./notifications/notificationsDetails/notificationsDetails.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MatMenuModule } from "@angular/material/menu";
import { FileService } from "app/shared/services/File.service";
import { notificationMessageViewerComponent } from "./notifications/notificationsDetails/notificationMessageViewer/notificationMessageViewer.component";
import { ProfileObserverManagerService } from "../services/Profile/profile.ObserverManager.service";
import { chatNotificationsWarningComponent } from "./chat-notifications/chat-notifications-warning/chat-notifications-warning.component";
import { chatNotificationMessageViewerComponent } from "./chat-notifications/chat-notificationsDetails/chat-notificationMessageViewer/chat-notificationMessageViewer.component";
import { chatNotificationsDetailsComponent } from "./chat-notifications/chat-notificationsDetails/chat-notificationsDetails.component";
import { GalleryComponent } from "./gallery/gallery.component";

// import {MatDialogModule} from '@angular/material/dialog'
const components = [
  HeaderTopComponent,
  SidebarTopComponent,
  NotificationsWarningComponent,

  notificationMessageViewerComponent,
  SidenavComponent,
  notificationsDetailsComponent,
  chatNotificationsWarningComponent,
  chatNotificationMessageViewerComponent,
  chatNotificationsDetailsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  MapLayoutComponent,
  TopbarLayoutComponent,
  AdminLayoutComponent,
  GuestLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  CustomizerComponent,
  ButtonLoadingComponent,
  EgretSidebarComponent,
  FooterComponent,
  EgretSidebarTogglerDirective,
  BottomSheetShareComponent,
  GalleryComponent

];

@NgModule({
  imports: [
    SharedDirectivesModule,
    SharedMaterialModule,
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    PerfectScrollbarModule,
    InfiniteScrollModule,
    // MatDialogModule,
    SharedPipesModule,
    MatCarouselModule
  ],
  providers: [
    ThemeService,
    SharedService,
    ProfileService,
    ProfileObserverManagerService,
    FileService,
  ],
  declarations: components,
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent,
    BottomSheetShareComponent,
  ],
  exports: components,
})
export class SharedComponentsModule {}
