<div class="app-admin-wrap" [dir]="layoutConf?.dir">
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->

  <app-header-top
    *ngIf="notificationPanel  && layoutConf.navigationPos === 'top'"
    [notificPanel]="notificationPanel"
    [chatNotificationPanel]="chatNotificationPanel"
    (showChatNotificationdetailsEmitter)="GetshowChatNotificationdetails($event)"
  >
  </app-header-top>  
  <!-- Main Container -->
  <mat-sidenav-container
    [dir]="layoutConf.dir"
    class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
    [ngClass]="adminContainerClasses"
  >
    <mat-sidenav-content>
      <!-- SIDEBAR -->
      <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
      <app-sidebar-side
        *ngIf="layoutConf.navigationPos === 'side'"
        (mouseenter)="sidebarMouseenter($event)"
        (mouseleave)="sidebarMouseleave($event)"
      ></app-sidebar-side>

      <!-- Top navigation layout (navigation for mobile screen) -->
      <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
      <app-sidebar-top
        *ngIf="layoutConf.navigationPos === 'top' && layoutConf.isMobile"
      ></app-sidebar-top>

      <!-- App content -->
      <div
        class="main-content-wrap"
        id="main-content-wrap"
        [perfectScrollbar]=""
        [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar"
      >
        <!-- Header for side navigation layout -->
        <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
        <app-header-side
          *ngIf="layoutConf.navigationPos === 'side'"
          [notificPanel]="notificationPanel"
          [chatNotificationPanel]="chatNotificationPanel"
          (showChatNotificationdetailsEmitter)="GetshowChatNotificationdetails($event)"
        >
        </app-header-side>

        <div
          class="rightside-content-hold"
          id="rightside-content-hold"
          [perfectScrollbar]="scrollConfig"
          [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar"
        >
          <!-- View Loader -->
          <div
            class="view-loader"
            *ngIf="isModuleLoading"
            style="position: fixed"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div class="spinner">
              <div class="double-bounce1 mat-bg-accent"></div>
              <div class="double-bounce2 mat-bg-primary"></div>
            </div>
          </div>
          <!-- Breadcrumb -->
          
          <app-breadcrumb></app-breadcrumb>
          <!-- View outlet -->
          <div class="container-dynamic">
            <router-outlet></router-outlet>
          </div>
          <span class="m-auto" *ngIf="!layoutConf.footerFixed"></span>
          <app-footer
            *ngIf="!layoutConf.footerFixed"
            style="margin: 0 -0.33rem -0.33rem"
          ></app-footer>
        </div>
        <span class="m-auto" *ngIf="layoutConf.footerFixed"></span>
        <app-footer *ngIf="layoutConf.footerFixed"></app-footer>
      </div>
      <!-- View overlay for mobile navigation -->
      <div
        class="sidebar-backdrop"
        [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
        (click)="closeSidebar()"
      ></div>
    </mat-sidenav-content>

    <!-- Notificaation bar -->
  <!-- <mat-sidenav #notificationPanel mode="over" class=""  -->
    <mat-sidenav #notificationPanel mode="over" class="" position="end"   
            (backdropClick)="closeNotifications(notificationPanel)">
          <div class="nofication-panel" fxLayout="column">
              <div  class="notification-header mat-bg-primary pb-1">
                  <h6 class="m-0">{{ "Notifications" | translate }}</h6>
                  <button
              mat-icon-button
              class="close-btn"
              aria-label="Close"
              (click)="closeNotifications(notificationPanel)"
            >
              <mat-icon>close</mat-icon>
            </button>
                </div>
                
            
            <app-notificationsDetails
              *ngIf="notificationPanel.opened"
              [notificPanel]="notificationPanel"
            ></app-notificationsDetails>
          </div>
        
    </mat-sidenav>

    <!-- <mat-sidenav #chatNotificationPanel mode="over" class=""  -->
    <mat-sidenav #chatNotificationPanel mode="over" class="" position="end"    
    (backdropClick)="closeNotifications(chatNotificationPanel)">
  <div class="nofication-panel" fxLayout="column">
      <div  class="notification-header mat-bg-primary pb-1">
          <h6 class="m-0">{{ "Notifications" | translate }}</h6>
          <button
      mat-icon-button
      class="close-btn"
      aria-label="Close"
      (click)="closeNotifications(chatNotificationPanel)"
    >
      <mat-icon>close</mat-icon>
    </button>
        </div>
        
    
        <app-chatNotificationsDetails
        *ngIf="chatNotificationPanel.opened"
        [notificPanel]="chatNotificationPanel"
      ></app-chatNotificationsDetails>
  </div>

</mat-sidenav>



  </mat-sidenav-container>
</div>
