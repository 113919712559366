import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { HttpClient } from "@microsoft/signalr";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { SharedService } from "./shared.service";
import {
  PaginationFilter,
  PaginationResult,
  ResultResponse,
} from "../models/utils.model";
import { NotificationDto } from "../models/notificationDto.model";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    // public ls: LocalStoreService,
    private http: HttpClient, //  private router: Router, //  private loader: AppLoaderService
    private snack: MatSnackBar,
    private sharedService: SharedService
  ) {}
  //Observable<PaginationResult<ContactInfoDto[]>>
  public GetNotifications(
    paginationFilter: PaginationFilter
  ): Observable<ResultResponse<NotificationDto[]>> {
    let url = `${environment.apiURL}Notification/GetNotifications`;
    url = this.sharedService.buildUrlPaginationFilter(url, paginationFilter);
    return this.http.get<PaginationResult<NotificationDto[]>>(url).pipe(
      map((response) => {
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        // sessions / signin;
        // this.router.navigate(["/doctor"]);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
    // return this.http.get<ResultResponse<NotificationDto[]>>(url).pipe(
    //   map((response) => {
    //     if (response != null) {
    //       return response;
    //     }
    //   }),
    //   catchError((error) => {
    //     this.snack.open("error", null, { duration: 2000 });
    //     return throwError(error);
    //   })
    // );

    // let url = `${environment.apiURL}Profile/GetContactInInformations`;
    // url = this.sharedService.buildUrlPaginationFilter(url, paginationFilter);
    // return this.http.get<PaginationResult<ContactInfoDto[]>>(url).pipe(
    //   map((response) => {
    //     if (response != null) {
    //       return response;
    //     }
    //   }),
    //   catchError((error) => {
    //     this.snack.open("error", null, { duration: 2000 });
    //     return throwError(error);
    //   })
    // );
  }

  public GetNotificationsCount(): Observable<ResultResponse<number>> {
    let url = `${environment.apiURL}Notification/GetNotificationsCount`;

    return this.http.get<ResultResponse<number>>(url).pipe(
      map((response) => {
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  //#region  Chat Notifications
  public GetChatNotifications(
    paginationFilter: PaginationFilter
  ): Observable<ResultResponse<NotificationDto[]>> {
    let url = `${environment.apiURL}Notification/GetChatNotifications`;
    url = this.sharedService.buildUrlPaginationFilter(url, paginationFilter);
    return this.http.get<PaginationResult<NotificationDto[]>>(url).pipe(
      map((response) => {
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        // sessions / signin;
        // this.router.navigate(["/doctor"]);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
    // return this.http.get<ResultResponse<NotificationDto[]>>(url).pipe(
    //   map((response) => {
    //     if (response != null) {
    //       return response;
    //     }
    //   }),
    //   catchError((error) => {
    //     this.snack.open("error", null, { duration: 2000 });
    //     return throwError(error);
    //   })
    // );

    // let url = `${environment.apiURL}Profile/GetContactInInformations`;
    // url = this.sharedService.buildUrlPaginationFilter(url, paginationFilter);
    // return this.http.get<PaginationResult<ContactInfoDto[]>>(url).pipe(
    //   map((response) => {
    //     if (response != null) {
    //       return response;
    //     }
    //   }),
    //   catchError((error) => {
    //     this.snack.open("error", null, { duration: 2000 });
    //     return throwError(error);
    //   })
    // );
  }

  public GetChatNotificationsCount(): Observable<ResultResponse<number>> {
    let url = `${environment.apiURL}Notification/GetChatNotificationsCount`;

    return this.http.get<ResultResponse<number>>(url).pipe(
      map((response) => {
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  //#endregion
  public setNotificationIsReaded(notificationId: string) {
    // tslint:disable-next-line: triple-equals

    if (
      notificationId != null &&
      notificationId !== undefined &&
      notificationId != "00000000-0000-0000-0000-000000000000"
    ) {
      //update order status
      const url = `${environment.apiURL}Notification/NotificationIsReaded?notificationId=${notificationId}&isReaded=true`;
      return this.http.put(url, null).pipe(
        map((response) => {
          if (response != null) {
            return response;
          }
        }),
        catchError((error) => {
          this.snack.open("snack", null, { duration: 2000 });
          return throwError(error);
        })
      );
    }
  }
  public setChatNotificationIsReaded(notificationId: string) {
    // tslint:disable-next-line: triple-equals

    if (
      notificationId != null &&
      notificationId !== undefined &&
      notificationId != "00000000-0000-0000-0000-000000000000"
    ) {
      //update order status
      const url = `${environment.apiURL}Notification/ChatNotificationIsReaded?notificationId=${notificationId}&isReaded=true`;
      return this.http.put(url, null).pipe(
        map((response) => {
          if (response != null) {
            return response;
          }
        }),
        catchError((error) => {
          this.snack.open("snack", null, { duration: 2000 });
          return throwError(error);
        })
      );
    }
  }
}
