export interface IUser {
  Id?: string;
  //displayName?: string;
  role?: string;
  menuItems: any[];
  // username : string;
  profileImageUrl: string;
  profileImage: any;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  // Deactivated: boolean;
  // Organisation: string;
  // UserType: number;
  // Address_Country: number;
  // Address_Details: string;
  isNewsLetter: boolean;
  language: Language;
}
export interface UserProfileViewModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  language: Language;
}
export interface IUserProfileDto {
  Id?: string;
  profileImageUrl: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  role: string;
  isEmailConfirmed: boolean;
  language: Language;
}
export enum Language {
  arabic,
  english,
}
export interface Singup {
  password: string;
  confirmedPassword: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  profileImage: string;
}

export const initialUserState: IUser = {
  Id: null,
  //displayName: null,
  role: null,
  menuItems: [],
  // username: null,
  email: null,
  phone: null,
  profileImageUrl: null,
  profileImage:null,
  firstName: null,
  lastName: null,
  language: 1,
  //Deactivated: true,
  //Organisation: null,
  // UserType: 5,
  // Address_Country: 1,
  // Address_Details: null,
  isNewsLetter: false,
};
export interface personUser {
  id: string;
  password: string;
  confirmedPassword: string;
  email: string;
  personId:string;

};
export interface personUserDto {
  
  id: string;
  email: string;
  isActive:boolean;
  personId:string;
  logoImage: any;
  firstName: string;
  lastName: string;
  code: string;
  phone: string;
  profileImageUrl: string;
  profileImageContentType: string;

}