import { Component, Input, OnInit } from "@angular/core";
import { environment } from "@environments/environment";
import { NotificationService } from "app/shared/services/notification.service";
import { SignalRNotificationService } from "app/shared/services/SignalRNotification.service";
import { Observable } from "rxjs";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { FileService } from "app/shared/services/File.service";
@Component({
  selector: "app-chatNotificationMessageViewer",
  templateUrl: "./chat-notificationMessageViewer.component.html",
  styleUrls: ["./chat-notificationMessageViewer.component.scss"],
})
export class chatNotificationMessageViewerComponent implements OnInit {
  @Input() notification;
  senderImage: any;

  constructor(
    private sanitizer: DomSanitizer,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    if (this.notification) {
      // senderName:row.sender.Name,
      // senderimg:row.sender.imageURL

      if (this.isExternalURL(this.notification.senderimg)) {
        this.senderImage = this.notification.senderimg;
      } else {
        this.ThumbnilBuilder(this.notification.senderimg);
      }

      //
    }
  }
  isExternalURL(link: string): boolean {
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
      return true;
    } else {
      return false;
    }
  }

  ThumbnilBuilder(logo: string) {
    
    //let logo = result.farm.logo;
    if (logo) {
      this.GetImageFileThumbnil(logo).subscribe((file) => {
        this.senderImage=file;
        //let objectURL = URL.createObjectURL(file);
        //this.senderImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    }else{
      this.senderImage=environment.dummyFaceImage;
    }
  
    // noImage.png
  }
  GetImageFileThumbnil(thumbnailUrl): Observable<SafeUrl> {
    return this.fileService.GetFileBy(thumbnailUrl);
  }
}
