<div class="slider-container">
    <mat-carousel
      timings="250ms ease-in-out"
      [autoplay]="true"
      interval="5000"
      [loop]="true"
      [hideArrows]="true"
      [hideIndicators]="false">
      <mat-carousel-slide *ngFor="let image of images" [image]="image"></mat-carousel-slide>
    </mat-carousel>
  </div>