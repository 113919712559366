import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppComfirmComponent } from './app-confirm.component';

interface confirmData {
  title?: string,
  message?: string,
  isconfirm?: boolean,
  color?:string
}

@Injectable()
export class AppConfirmService {

  constructor(private dialog: MatDialog) { }

  public confirm(data:confirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    data.isconfirm = data.isconfirm || true;
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message , isconfirm: data.isconfirm ,color:data.color}
    });
    return dialogRef.afterClosed();
  }

  public showconfirm(title: string , message: string ): Observable<boolean> {
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '380px',
      disableClose: true,
      data: {title: title, message: message , isconfirm: true ,color:"accent"}
    });
    return dialogRef.afterClosed();
  }

  public showWarn(title: string , message: string ): Observable<boolean> {
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '380px',
    
      disableClose: true,
      data: {title: title, message: message , isconfirm: false,  color:"warn" }
    });
    return dialogRef.afterClosed();
  }
  public showmessage(title: string , message: string )  {
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '380px',
      disableClose: true,
      data: {title: title, message: message , isconfirm: false }
    });
    
  }
}