import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SignalRNotificationService } from "./SignalRNotification.service";

@Injectable({
  providedIn: "root",
})
export class SignalRNotificationObserverManagerService {
  constructor() {}
  private connectionSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  connectionStartRequestObserver$: Observable<any> =
    this.connectionSubject.asObservable();

  private hubConnectionSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  connectionStartedObserver$: Observable<any> =
    this.hubConnectionSubject.asObservable();

  iNeedConnection() {
    //let result :ResultResponse<string>=response as ResultResponse<string>

    this.connectionSubject.next("");
  }
  iGothubConnectionStared(hubConnection) {
    //let result :ResultResponse<string>=response as ResultResponse<string>

    this.hubConnectionSubject.next(hubConnection);
  }
}
