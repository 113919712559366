import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
//import { GestureConfig } from "@angular/material/core";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { InMemoryWebApiModule } from "angular-in-memory-web-api";
import { InMemoryDataService } from "./shared/inmemory-db/inmemory-db.service";
import { rootRouterConfig } from "./app.routing";
import { AppComponent } from "./app.component";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ErrorHandlerService } from "./shared/services/error-handler.service";
import { AngularSplitModule } from "angular-split";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TokenInterceptor } from "./shared/interceptors/token.interceptor";
import { RoutePartsService } from "./shared/services/route-parts.service";

import { NavigationService } from "./shared/services/navigation.service";
import { ThemeService } from "./shared/services/theme.service";
import { SharedModule } from "./shared/shared.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { MatSelectCountryModule } from "@angular-material-extensions/select-country/src/public-api";

//import { MatSelectCountryModule } from "../../projects/angular-material-extensions/select-country/public-api";


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    BrowserModule,
    //MatSelectCountryModule,
    MatSelectCountryModule.forRoot('all'),
    // MatSelectCountryModule.forRoot("default"),

    AngularSplitModule.forRoot(),
    // HttpClientModule,
    PerfectScrollbarModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, {
      passThruUnknownUrl: true,
    }),
    // RouterModule.forChild(rootRouterConfig),
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
  ],
  exports: [SharedModule,MatSelectCountryModule],
  declarations: [AppComponent],
  providers: [
    RoutePartsService,
    ThemeService,
    NavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  //  { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    // REQUIRED IF YOU USE JWT AUTHENTICATION
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
