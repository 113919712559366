export const systemLanguage = [
  {
    id: 0,
    name: "English",
    code: "en",
    flag: "flag-icon-us",
  },
  // { id: "0",
  //   name: "ES",
  //   code: "es",
  //   flag: "flag-icon-es",
  // },
  {
    id: 1,
    name: "عربي",
    code: "ar",
    flag: "flag-icon-kw",
  },
];
