<footer class="main-footer">
  <div class="container-dynamic">
    <div class="footer-content d-flex">
      <span class="m-auto"></span>
      <p class="footer-text">
        Design & Developed by: 
        <a href="https://nheroz.com" target="_blank" rel="noopener" class="footer-link">
          nheroz
        </a>
      </p>
    </div>
  </div>
</footer>