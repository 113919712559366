import { environment } from "@environments/environment";
import { PaginationFilter, PaginationResult } from "../models/utils.model";
import { debug } from "console";

enum scrolllerDataStatus{
    full,empty
}
enum scrolllerActionStatus{
    getData,delete,update,insert
}
export enum scrolllerDirection{
    ScrollUp,ScrollDawen,SamePage
}
enum scrolllerAction{
    GetfromPrevous,ScrollDawen,SamePage,
}

export function SetPaginationBeforeSendRequest(pagination :PaginationResult<any>,direction:scrolllerDirection,OrderBy:string) {



   let  paginationFilter: PaginationFilter;
   const pageSize=environment.PaganatiorPageSize
   let pageNumber=0;
    if(pagination ==undefined){
        pageNumber=0;
    }
    else
    {
        
        let totalRecordsForCurrentPage=pagination.data.length;
        if( direction ==scrolllerDirection.ScrollDawen && pageSize>totalRecordsForCurrentPage){
            return undefined;
        }
    
        if( direction ==scrolllerDirection.SamePage){
            pageNumber=pagination.pageNumber;
        }
        if( direction ==scrolllerDirection.ScrollUp){
            pageNumber=pagination.pageNumber-1;
        }
        if( direction ==scrolllerDirection.ScrollDawen){
            pageNumber=pagination.pageNumber+1;
        }
    }
    if(pageNumber <0){
        return undefined;
    }

  
  

   

    paginationFilter= {
        OrderBy: null,
        PageSize: environment.PaganatiorPageSize,
        PageNumber:pageNumber,
          
        nextUrl: null,
      };
      
return paginationFilter;



 
}
export function SetPaginationAfterResponse(newPagination :PaginationResult<any>,oldPagination:PaginationResult<any>) {

   
   if(newPagination.pageNumber ==0){
    return newPagination;
   }
   if(newPagination.pageNumber <=oldPagination.pageNumber){
    return newPagination; 
}
   

    if(newPagination.data.length >0){
        return newPagination;
    }
    if(newPagination.data.length ==0 && newPagination.pageNumber> oldPagination.pageNumber){
        return oldPagination;
    }
  }