import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { IUser } from "app/shared/models/user.model";
import { FileService } from "app/shared/services/File.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "@environments/environment";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  user: IUser = {} as IUser;
  userProfileImgURL: any;
  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService,
    private sanitizer: DomSanitizer,
    private fileService: FileService
  ) {}

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

    this.menuItemsSub = this.navService.menuItems$.subscribe((menuItem) => {
      this.menuItems = menuItem;
      this.menuItems = this.menuItems.filter(
        (item) => item.type === "icon" && item.activeFor != "topbar"
      );

      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon" && item.activeFor != "topbar"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
    
    this.autoSignIn();



  }
getlogedInUserphoto(profileImageUrl){

  if (
    profileImageUrl != "" &&
    profileImageUrl != undefined &&
    profileImageUrl != null
  ) {
    this.fileService.GetFileBy(profileImageUrl,'profile').subscribe((file) => {
      this.userProfileImgURL=file;
     // let objectURL = URL.createObjectURL(file);
     // this.userProfileImgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    });
  }else{
    this.userProfileImgURL = environment.dummyFaceImage;
  }

  

}
  autoSignIn() {
    if (this.jwtAuth.ls.getItem("Farmenzo_APP_USER")) {
      this.user = this.jwtAuth.ls.getItem("Farmenzo_APP_USER");
      this.getlogedInUserphoto(this.user.profileImageUrl);
      this.navService.menuItems.next(
        this.jwtAuth.ls.getItem("Farmenzo_APP_USER").menuItems
      );
      this.menuItems = this.jwtAuth.ls.getItem("Farmenzo_APP_USER").menuItems;
    }
  }

  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false,
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true,
      });
    }
  }
}
