import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CreateOrderviewModel,
  OrderDto,
  OrderStatus,
  UpdateOrderByBuyerViewModel,
  UpdateOrderStatusViewModel,
} from "app/shared/models/order.model";
import {
  ContactInfoDto,
  ContactInfoViewModel,
  UserAddressDto,
  UserAddressViewModel,
} from "app/shared/models/Profile.model";
import {
  PaginationFilter,
  PaginationResult,
  ResultResponse,
} from "app/shared/models/utils.model";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError, map } from "rxjs/operators";
import { SharedService } from "../shared.service";

@Injectable()
export class ProfileObserverManagerService {
  constructor(
    // public ls: LocalStoreService,
    private http: HttpClient, //  private router: Router, //  private loader: AppLoaderService
    private snack: MatSnackBar,
    private sharedService: SharedService
  ) {}
  newContactInfoIdSubject: Subject<string> = new Subject<string>();
  newContactInfoIdObserver$: Observable<any> =
    this.newContactInfoIdSubject.asObservable();
  newAddressIdSubject: Subject<string> = new Subject<string>();
  newAddressIdSubjectObserver$: Observable<any> =
    this.newAddressIdSubject.asObservable();
  CallnewContactInfoIdObserver(response: ResultResponse<string>) {
    //let result :ResultResponse<string>=response as ResultResponse<string>
    this.newContactInfoIdSubject.next(response.data);
  }
  CallnewAddressIdObserver(response: ResultResponse<string>) {
    //let result :ResultResponse<string>=response as ResultResponse<string>
    this.newAddressIdSubject.next(response.data);
  }
}
