import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppLoaderComponent } from './app-loader.component';
import { debug } from 'console';

interface Config {
  width?: string
}

@Injectable()
export class AppLoaderService {
  dialogRef: MatDialogRef<AppLoaderComponent>;
  calles:string[]=[];
  constructor(private dialog: MatDialog) { }

  public open(caller:string,title: string = 'Please wait', config: Config = {width: '150px'}): Observable<boolean> {
 
  
    if( this.calles.length ==0 ){
      this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: false, backdropClass: 'light-backdrop'});
      this.dialogRef.updateSize(config.width);
      this.dialogRef.componentInstance.title = title;
    }
    let existCaller= this.calles.find(p=> p ==caller);
    if(existCaller == undefined){
      this.calles.push(caller)
    }

    return  this.dialogRef.afterClosed();;
  }

  public close(caller:string) {
 
    if(this.dialogRef){
      let existCaller= this.calles.find(p=> p ==caller);
      if(existCaller ){
        this.calles.splice(this.calles.findIndex(x => x == existCaller), 1);
       
      }
      if(this.calles.length ==0){
       
        this.dialogRef.close();
      }
      
    }
      
  }
}
