import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError, map, switchMap } from "rxjs/operators";
import { LocalStoreService } from "./local-store.service";

@Injectable()
export class FileService {
  constructor(
     public ls: LocalStoreService,
    private http: HttpClient, //  private router: Router, //  private loader: AppLoaderService
    private snack: MatSnackBar
  ) {}
public removeFileFromCash(filePath: string,cachedImageName:string){
  this.ls.clear();
  //localStorage.removeItem( `${cachedImageName}_${filePath}`);
}
  public GetFileBy(filePath: string,cachedImageName:string=""): Observable<any> {

    if (filePath == null || filePath === undefined || filePath === "") return;
  
    // Create a unique key for the image in localStorage
    let cachedImage =""
    let key=`${cachedImageName}_${filePath}`; 
    let url = `${environment.apiURL}File/GetFile?url=${filePath}`;
    
    if(cachedImageName !=""){
            
         cachedImage =  this.ls.getItem(key);
        if (cachedImage) {
          // If the image is cached, return it as an observable
          return of(cachedImage);
        }else
        {
       
          return this.http.get(url, { responseType: 'blob' }).pipe(
            switchMap((response) => {
              if (response != null) {
                return new Observable<string>((observer) => {
                  let base64data = '';
                  const reader = new FileReader();
                  
                  reader.readAsDataURL(response);
          
                  reader.onloadend = () => {
                    
                    if (reader.result) {
                      base64data = reader.result as string;
                      if (cachedImageName !== '') {
                        const key = `${cachedImageName}_${filePath}`;
                        this.ls.setItem(key, base64data);
                      }
                      observer.next(base64data);  // Emit the result after loading
                      observer.complete();  // Complete the observable
                    }
                  };
          
                  reader.onerror = (error) => {
                    observer.error('Error reading Blob: ' + error);
                  };
                });
              } else {
                return of('');  // Return empty string if the response is null
              }
            }),
            catchError((error) => {
              this.snack.open('error', null, { duration: 2000 });
              return throwError(error);
            })
          );
        } 
    }else{

      
  
      return this.http.get(url, { responseType: 'blob' }).pipe(
        switchMap((response) => {
          if (response != null) {
            return new Observable<string>((observer) => {
              let base64data = '';
              const reader = new FileReader();
              
              reader.readAsDataURL(response);
      
              reader.onloadend = () => {
                
                if (reader.result) {
                  base64data = reader.result as string;
          
                  observer.next(base64data);  // Emit the result after loading
                  observer.complete();  // Complete the observable
                }
              };
      
              reader.onerror = (error) => {
                observer.error('Error reading Blob: ' + error);
              };
            });
          } else {
            return of('');  // Return empty string if the response is null
          }
        }),
        catchError((error) => {
          this.snack.open('error', null, { duration: 2000 });
          return throwError(error);
        })
      );
    }
   
     
    
  }
}
