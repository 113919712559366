import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CreateOrderviewModel,
  OrderDto,
  OrderStatus,
  UpdateOrderByBuyerViewModel,
  UpdateOrderStatusViewModel,
} from "app/shared/models/order.model";
import {
  ContactInfoDto,
  ContactInfoViewModel,
  UserAddressDto,
  UserAddressViewModel,
} from "app/shared/models/Profile.model";
import {
  PaginationFilter,
  PaginationResult,
  ResultResponse,
} from "app/shared/models/utils.model";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError, map } from "rxjs/operators";
import { SharedService } from "../shared.service";
import { ProfileObserverManagerService } from "./profile.ObserverManager.service";
import { AppLoaderService } from "../app-loader/app-loader.service";

@Injectable()
export class ProfileService {
  constructor(
    // public ls: LocalStoreService,
    private http: HttpClient, //  private router: Router, //  private loader: AppLoaderService
    private snack: MatSnackBar,
    private sharedService: SharedService,
    private profileObserverManagerService:ProfileObserverManagerService,
    private loader: AppLoaderService,
  ) {}

  public CreateUserAddress(userAddress: UserAddressViewModel) {
    let caller="CreateUserAddress";
    // tslint:disable-next-line: triple-equals
    if (userAddress != null && userAddress !== undefined) {
      //add new farm
      this.loader.open(caller);
      return this.http
        .post(`${environment.apiURL}Profile/create-user-address`, userAddress)
        .pipe(
          map((response) => {
            this.loader.close(caller);
            if (response != null) {
              let result :ResultResponse<string>=response as ResultResponse<string>
              this.profileObserverManagerService.CallnewContactInfoIdObserver(result)
            }
          }),
          catchError((error) => {
            this.loader.close(caller);
            this.snack.open("snack", null, { duration: 2000 });
            return throwError(error);
          })
        );
    }
  }
  public SetUserLanguage(userlanguage: string) {
    let caller="SetUserLanguage";
    // tslint:disable-next-line: triple-equals
    if (userlanguage != null && userlanguage !== undefined) {
      //add new farm
      var param = {
        language: userlanguage,
      };
      this.loader.open(caller);
      return this.http
        .put(
          `${environment.apiURL}Profile/set-user-language?language=${userlanguage}`,
          null
        )
        .pipe(
          map((response) => {
            this.loader.close(caller);
            if (response != null) {
              return response;
            }
          }),
          catchError((error) => {
            this.loader.close(caller);
            this.snack.open("snack", null, { duration: 2000 });
            return throwError(error);
          })
        );
    }
  }
  public CreateContactInfo(contactInfo: ContactInfoViewModel) {
    let caller="CreateContactInfo";
    // tslint:disable-next-line: triple-equals
    if (contactInfo != null && contactInfo !== undefined) {
      //add new farm
      this.loader.open(caller);
      return this.http
        .post(`${environment.apiURL}Profile/create-contact-info`, contactInfo)
        .pipe(
          map((response) => {
            this.loader.close(caller);
            if (response != null) {
              if(response){
                let result :ResultResponse<string>=response as ResultResponse<string>
                this.profileObserverManagerService.CallnewContactInfoIdObserver(result)
                return response;
                }
              return response;
            }
          }),
          catchError((error) => {
            this.loader.close(caller);
            this.snack.open("snack", null, { duration: 2000 });
            return throwError(error);
          })
        );
    }
  }

  public GetUserContactInInformations(
    paginationFilter: PaginationFilter
  ): Observable<PaginationResult<ContactInfoDto[]>> {
    let url = `${environment.apiURL}Profile/get-contact-informations`;
    url = this.sharedService.buildUrlPaginationFilter(url, paginationFilter);
    let caller="GetUserContactInInformations";
    this.loader.open(caller);
    return this.http.get<PaginationResult<ContactInfoDto[]>>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  public GetUserAddresses(
    paginationFilter: PaginationFilter
  ): Observable<PaginationResult<UserAddressDto[]>> {
    let url = `${environment.apiURL}Profile/get-user-addresses`;
    url = this.sharedService.buildUrlPaginationFilter(url, paginationFilter);
    let caller="GetUserAddresses";
    this.loader.open(caller);
    return this.http.get<PaginationResult<UserAddressDto[]>>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  public GetUserAddress(id): Observable<UserAddressDto> {
    let caller="GetUserAddress";
    const url = `${environment.apiURL}Profile/get-user-address?id=${id}`;
    this.loader.open(caller);
    return this.http.get<UserAddressDto>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  public GetUserContactInfo(id): Observable<ResultResponse<ContactInfoDto>> {
    let caller="GetUserContactInfo";
    const url = `${environment.apiURL}Profile/get-user-contact-info?id=${id}`;
    this.loader.open(caller);
    return this.http.get<ResultResponse<ContactInfoDto>>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }

  public GetUserDefualtContactInfo(
    userId
  ): Observable<ResultResponse<ContactInfoDto>> {
    let caller="GetUserDefualtContactInfo";
    const url = `${environment.apiURL}Profile/get-user-defualt-contact-info?id=${userId}`;
    this.loader.open(caller);
    return this.http.get<ResultResponse<ContactInfoDto>>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  public GetUserDefualtAddress(
    userId
  ): Observable<UserAddressDto> {
    let caller="GetUserDefualtAddress";
    const url = `${environment.apiURL}Profile/get-user-defualt-address?id=${userId}`;
    this.loader.open(caller);
    return this.http.get<UserAddressDto>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }

  public DeleteUserAddress(id: string): Observable<boolean> {
    let caller="DeleteUserAddress";
    let url = `${environment.apiURL}Profile/delete-user-address?userAddressId=${id}`;
    this.loader.open(caller);
    return this.http.delete<boolean>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          this.snack.open("Address deleted successfully", null, {
            duration: 2000,
          });
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("snack", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  public DeleteContactInfo(id: string): Observable<boolean> {
    let caller="DeleteContactInfo";
    let url = `${environment.apiURL}Profile/delete-contact-Info?contactInfoId=${id}`;
    this.loader.open(caller);
    return this.http.delete<boolean>(url).pipe(
      map((response) => {
        this.loader.close(caller);
        if (response != null) {
          this.snack.open("Contact Info deleted successfully", null, {
            duration: 2000,
          });
          return response;
        }
      }),
      catchError((error) => {
        this.loader.close(caller);
        this.snack.open("snack", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
}
