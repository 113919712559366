
<div fxLayout="row" fxFlex="100" [ngClass]="{
  'notific-item-message': notification.isReaded === true,
  'notific-item-message notific-item-message-notReaded': notification.isReaded === false
}">

 
  <img [src]="senderImage" alt="" mat-list-avatar class="mr-1" />

  <div fxFlex="100" >{{ notification.message | excerpt: 50 }}</div>
  <span fxFlex></span>
  <small class="text-muted mr-1">
    {{ notification.createdOn | relativeTime }}</small
  >
</div>
<!-- <mat-chip mat-sm-chip [color]="'warn'" [selected]="n.isReaded">{{n.isReaded ? 'active' : 'closed'}}</mat-chip> -->
