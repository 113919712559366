import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import {
  IUser,
  IUserProfileDto,
  UserProfileViewModel,
} from "../../models/user.model";
import { of, BehaviorSubject, throwError, Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "environments/environment";
import { LogIntokenDto } from "app/shared/models/LogIntokenDto";
import { Coordinate } from "ol/coordinate";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { FileService } from "../File.service";
import { TranslateService } from "@ngx-translate/core";
import { systemLanguage } from "app/shared/helpers/languares";
// ================= you will get those data from server =======
@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: IUser = {} as IUser;
  user$ = new BehaviorSubject<IUser>(this.user);
  signingIn: Boolean;
  JWT_TOKEN = "JWT_TOKEN";
  Farmenzo_APP_USER = "Farmenzo_APP_USER";
  USER_GEO_INFO = "USER_GEO_INFO";
  public availableLangs = systemLanguage;
  currentLang = this.availableLangs[0];
  constructor(
    public ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private snack: MatSnackBar,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    public translate: TranslateService,
  ) {}

  public getusergeoip() {
    this.signingIn = true;
    return this.http
      .get(
        "https://api.ipgeolocation.io/ipgeo?apiKey=cf030da821e044f3ad4a97f623e29614"
      )
      .pipe(
        map((res: any) => {
          if (res.latitude) this.setUserCountry(res);
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public signin(username, password) {
    this.signingIn = false;
    return this.http
      .post(
        `${environment.apiURL}Account/LogIn`,
        { emailOrPhone: username, password: password },
        { responseType: "text" }
      )
      .pipe(
        map((response) => {
          if (response != null) {
            environment.loggedin = true;
            {
              this.setToken(response);
              return response;
            }
          }

          return "Unable to connect";
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          return throwError(error);
        })
      );
  }
  public setNewPassword(username, password,confirmedPassword,currentpassword) {
    
    this.signingIn = false;
    return this.http
      .put(
        `${environment.apiURL}Account/update-password`,
        { emailOrPhone: username, password: password,confirmedpassword:confirmedPassword,oldpassword:currentpassword },
        { responseType: "text" }
      )
      .pipe(
        map((response) => {
          if (response != null) {
            environment.loggedin = true;
            {
              this.setToken(response);
              return response;
            }
          }

          return "Unable to connect";
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          return throwError(error);
        })
      );
  }
  
  public SetNewPasswordForVerification(username, password,confirmedPassword) {
    
    this.signingIn = false;
    return this.http
      .put(
        `${environment.apiURL}Account/update-password-by-verification`,
        { emailOrPhone: username, password: password,confirmedpassword:confirmedPassword },
        { responseType: "text" }
      )
      .pipe(
        map((response) => {
          if (response != null) {
            environment.loggedin = true;
            {
              this.setToken(response);
              return response;
            }
          }

          return "Unable to connect";
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          return throwError(error);
        })
      );
  }
  public sendVirificationCodeToEmail(email: string) {
    this.signingIn = false;
    return this.http
      .post(
        `${environment.apiURL}Account/send-virification-code-to-email?email=${email}`,
        null
        // { responseType: "text" }
      )
      .pipe(
        map((response: LogIntokenDto) => {
          if (response != null) {
            environment.loggedin = true;
            {
              this.setToken(response.token);
              return response.callBackUrl;
            }
          }

          return "Unable to connect";
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          return throwError(error);
        })
      );
  }
  public sendVerifyCodeToEmail(code: string):Observable<string|boolean> {
    this.signingIn = false;
    return this.http
      .put<boolean>(
        `${environment.apiURL}Account/verify-code?code=${code}`,
        null
       //  { responseType: "text" }
      )
      .pipe(
        map((response) => {
          if (response != null) {
       
          }

          return "Unable to connect";
        }),
        catchError((error) => {
          this.snack.open("Unable To verify code", null, { duration: 2000 });
          return throwError(error);
        })
      );
  }
  
  public exteranlLogin(provider: number, idToken: string, callBackUrl: string) {
    this.signingIn = false;
    return this.http
      .post(
        `${environment.apiURL}Account/ExteranlLogin?provider=${provider}&idToken=${idToken}&callBackUrl=${callBackUrl}`,
        null
        // { responseType: "text" }
      )
      .pipe(
        map((response: LogIntokenDto) => {
          if (response != null) {
            environment.loggedin = true;
            {
              this.setToken(response.token);
              return response.callBackUrl;
            }
          }

          return "Unable to connect";
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          return throwError(error);
        })
      );
  }
  public GetUserProfileWithMenu() {
    this.signingIn = true;
    return this.http
      .get(`${environment.apiURL}Profile/GetUserProfileWithMenu`)
      .pipe(
        map((UserProfile: IUser) => {
          if (UserProfile != null) {
            environment.loggedin = true;
            const user: IUser = UserProfile;

            this.SetUser(user);
            this.signingIn = true;
            return user;
          }

          return "Unable to connect";
        }),
        catchError((error) => {
          this.snack.open("Unable To Sign In", null, { duration: 2000 });
          return throwError(error);
        })
      );
  }
  public GetUserProfile(): Observable<IUserProfileDto> {
    const url = `${environment.apiURL}Profile/GetUserProfile`;
    return this.http.get<IUserProfileDto>(url).pipe(
      map((response) => {
        if (response != null) {
          return response;
        }
      }),
      catchError((error) => {
        this.snack.open("error", null, { duration: 2000 });
        return throwError(error);
      })
    );
  }
  public UpdateUserProfile(userProfile: UserProfileViewModel) {
    // tslint:disable-next-line: triple-equals
    if (userProfile != null && userProfile !== undefined) {
      return this.http
        .put(`${environment.apiURL}Profile/update-user-profile`, userProfile)
        .pipe(
          map((response) => {
            if (response != null) {
              return response;
            }
          }),
          catchError((error) => {
            this.snack.open("snack", null, { duration: 2000 });
            return throwError(error);
          })
        );
    }
  }
  public sendforgetemail(Email: string) {
    return this.http
      .post(`${environment.apiURL}user/forgetpassword`, { Email: Email })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
 SetCurrentUserSetting(){
  this.SetTranslate();
 }
  public checkTokenIsValid() {
    let id = this.ls.getItem(this.Farmenzo_APP_USER).id;
    let token = this.ls.getItem(this.JWT_TOKEN);
    return this.http
      .post(`${environment.apiURL}user/signinbytoken`, { id, token })
      .pipe(
        map((res) => {
          if (res) {
            environment.loggedin = true;
            this.setToken(this.getJwtToken());
            return res;
          } else {
            this.snack.open("Token Expired...", "OK", { duration: 6000 });
            this.signout();
            return;
          }
        }),
        catchError((error) => {
          this.snack.open("Token Expired....", "OK", { duration: 6000 });
        //  this.router.navigateByUrl("home");
          this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["home"]));
          return of(error);
        })
      );
  }
  public AutoSigninByToken() {
    let token = this.ls.getItem(this.JWT_TOKEN);
    if (!token) {
      return of({ IsSuccess: false });
    }

    return this.http.post(`${environment.apiURL}user/signinbytoken`, "").pipe(
      map((res: string) => {
        if (res) {
          //  this.setUserAndToken(this.getJwtToken(), res.data, true);
          this.setToken(res);
          this.SetTranslate();
          return res;
        } else {
          this.signout();
          this.snack.open("Token Expired.", null, { duration: 1000 });
          return res;
        }
      }),
      catchError((error) => {
        this.signout();
        this.snack.open("Token Expired..", null, { duration: 3000 });
        return of(error);
      })
    );
  }
  public signout() {
    ;
    this.setUserAndToken(null, null, false);
   // this.router.navigateByUrl("home");
   this.router
   .navigateByUrl("/", { skipLocationChange: true })
   .then(() => this.router.navigate(["home"]));
  }
  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }
  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.Farmenzo_APP_USER);
  }
  setToken(token: String) {
    this.token = token;
    this.isAuthenticated = true;
    this.ls.setItem(this.JWT_TOKEN, token);
    environment.loggedin = true;
  }
  setUserAndToken(token: String, user: IUser, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.ls.setItem(this.JWT_TOKEN, token);
    // this.user = user;
    // this.user$.next(user);
    // this.ls.setItem(this.Farmenzo_APP_USER, user);
    
    this.SetUser(user);

    environment.loggedin = true;
  }
  SetUser(user: IUser) {
     
    //get User profile photo
    this.user = user;
    if (user) {
      this.profileImageBuilder(this.user.profileImageUrl);
      this.user$.next(user);
      this.ls.setItem(this.Farmenzo_APP_USER, user);
      environment.loggedin = true;
      
      let lng= systemLanguage.find(p=>p.id ==user.language);
      this.translate.use(lng.code);
      return;
    }else{
      this.ls.setItem(this.Farmenzo_APP_USER, null);
    }
    // this.GetImageFileThumbnil(this.user.profileImageUrl);
    // this.user$.next(user);
    // this.ls.setItem(this.Farmenzo_APP_USER, user);
   
   
  }
  SetTranslate(){
  
    let user= this.ls.getItem(this.Farmenzo_APP_USER);
    if(user){
      let lng= systemLanguage.find(p=>p.id ==user.language);
      this.translate.use(lng.code);
    }else{
      this.translate.use(this.currentLang.code);
    }

  }
  profileImageBuilder(profileImageUrl) {
     
    if (profileImageUrl) {
      this.GetImageFileThumbnil(profileImageUrl).subscribe(
        (file:any) => {
          this.user.profileImage =file;
         // let objectURL = URL.createObjectURL(file);
          //this.user.profileImage =this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.user$.next(this.user);
          this.ls.setItem(this.Farmenzo_APP_USER, this.user);
          return;
        },
        (err) => {
         // this.user.profileImageUrl = environment.dummyFaceImage;
          this.user.profileImage = null;
          this.user$.next(this.user);
          this.ls.setItem(this.Farmenzo_APP_USER, this.user);
          return;
        }
      );
    }else{
     // this.user.profileImageUrl = environment.dummyFaceImage;
      this.user.profileImage = null;
      this.user$.next(this.user);
    this.ls.setItem(this.Farmenzo_APP_USER, this.user);
    }


    
    // noImage.png
  }
  GetImageFileThumbnil(thumbnailUrl,cachedImageName:string=""): Observable<SafeUrl> {
    return this.fileService.GetFileBy(thumbnailUrl,cachedImageName);
  }
  getDefaultMapCenter(coordinate: Coordinate | null) {
    if (coordinate) this.setUserGeoinfo(coordinate);
    var USER_GEO_INFO = this.ls.getItem(this.USER_GEO_INFO);
    if (USER_GEO_INFO) return [USER_GEO_INFO.longitude, USER_GEO_INFO.latitude];
    else return null;
  }
  setUserGeoinfo(coordinate: Coordinate) {
    let USERGEOINFO = { longitude: coordinate[0], latitude: coordinate[1] };
    return this.ls.setItem(this.USER_GEO_INFO, USERGEOINFO);
  }
  getUserGeoinfo() {
    return this.ls.getItem(this.USER_GEO_INFO);
  }
  setUserCountry(userGeoinfo) {
    this.ls.setItem(this.USER_GEO_INFO, userGeoinfo);
  }
}
