import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { FileService } from '../services/File.service';


@Pipe({
  name: 'asyncImage'
})
export class AsyncImagePipe implements PipeTransform {
  constructor(private fileService: FileService) {}

  transform(imageUrl: string): Observable<string> {
    return this.fileService.GetFileBy(imageUrl);
  }
}