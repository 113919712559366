import { Injectable } from "@angular/core";
import { PaginationFilter } from "../models/utils.model";

@Injectable()
export class SharedService {
  buildUrlPaginationFilter(
    originalURL,
    paginationFilter: PaginationFilter
  ): string {
    if (paginationFilter == null) {
      return originalURL;
    }
    if (paginationFilter != null && paginationFilter !== undefined) {
      if (paginationFilter.nextUrl != null) {
        return paginationFilter.nextUrl;
      }
      if (
        paginationFilter.PageNumber != null &&
        paginationFilter.PageNumber !== undefined &&
        paginationFilter.PageSize != null &&
        paginationFilter.PageSize !== undefined
      ) {
        if (originalURL.includes("?")) {
          originalURL = `${originalURL}&PageNumber=${paginationFilter.PageNumber}&PageSize=${paginationFilter.PageSize}`;
        } else {
          originalURL = `${originalURL}?PageNumber=${paginationFilter.PageNumber}&PageSize=${paginationFilter.PageSize}`;
        }
      }
      if (
        paginationFilter.OrderBy != null &&
        paginationFilter.OrderBy !== undefined
      ) {
        if (originalURL.includes("?")) {
          originalURL = `${originalURL}&OrderBy=${paginationFilter.OrderBy}`;
        } else {
          originalURL = `${originalURL}?OrderBy=${paginationFilter.OrderBy}`;
        }
      }
      return originalURL;
    }
  }
}
