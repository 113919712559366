import { Country } from "./country.model";
import { FarmProductSpecificationDto, FarmProductUnitDto, ProductType, UnitCategoryDto, UnitDto } from "./farmProduct.model";

import { UnitsCategoryDto } from "./QuotationDetialsFarmProduct";

export interface FarmViewModel {
  id: string;
  farmName: string;
  description: string;
  address: Address;
  logo: string;
}
export interface Farm {
  id: string;
  farmName: string;
  description: string;
  address: Address;
  owner: FarmUserDto;
  logo: string;
  logoImage: any;
  mapIcon: string;
}
export interface FarmSearch {
  farm: Farm;
  index: number;
}
export interface FarmProductSearchDto {
  index: number;
  farmProduct: FarmProductForSearchDto;
}
// export interface FarmProductForSearchDto {
//   id: string;
//   productId: string;
//   productImageURl: string|any;
//   name: string;
//   farmId: string;
//   farm: Farm;
//   thumbnailUrl: string;
//   description: string;
//   totalNumberOfUsers: number;
//   totalRate: number;
//   unitDefault: UnitDto;
//   unitDefaultId: string;
//   mapIcon: string;
//   unitsCategory:UnitsCategoryDto;
//   productMainCategoryId:string;
// }


export interface FarmProductForSearchDto {
    id: string;
    productImageURl: string;
    name: string;
    productCategoryName: string;
    productCategoryId: string;
    productType: ProductType;
    slug: string;
    farmProductUnitCategory: UnitCategoryDto;
    description: string;
    excerpt:string;
    thumbnailUrl: string;
    defaultUnitId: string | null;
    unitDefault: FarmProductUnitDto;
    farmProductPrices: FarmProductPriceForSearchDto[];
    farmProductSpecifications: FarmProductSpecificationDto[];
    totalRate: number;
    totalNumberOfUsers: number;
    farmId: string;
    farm: Farm;
    mapIcon: string;
}
export interface FarmProductPriceForSearchDto {
  unit: string;
  unitId: string;
  price: number;
}

export interface FarmUserDto {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface Address {
  id: string;
  city: string;
  governorate: string;
  country: Country;
  countryNumericCode: number;
  mark: string;
  details: string;
  geoLocation: GeoLocationDto;
}
export interface GeoLocationDto {
  id: string;
  latitude: number;
  longitude: number;
}

export interface GeoLocationViewModel {
  id: string;
  latitude: number;
  longitude: number;
}
export enum SEARCH_BY {
  FARMS = 1,
  PRODUCTS = 2,
}
