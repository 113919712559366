
<div fxLayout="row wrap" fxLayoutAlign="start start">
  <div
    fxFlex="100"
    class="infiniteScrollContainer NotificationListHeight"
    infiniteScroll
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onUp()"
    [scrollWindow]="false"
  >
    <mat-nav-list class="notification-list" role="list">
      <!-- Notification item -->
      <mat-list
        class="compact-list mb-2"
        *ngIf="notifications && notifications.length > 0"
      >
        <mat-list-item
          *ngFor="let n of notifications"
          (click)="onClick(n)"
          [ngClass]="{
            'notific-item': n.isReaded === true,
            'notific-item notific-item-notReaded': n.isReaded === false
          }"
          role="listitem"
          routerLinkActive="open"
          class="notification-item"
        >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="80">
              <app-notificationMessageViewer [notification]="n"></app-notificationMessageViewer>
            </div>
            <div fxFlex="20" class="notification-time text-right">
              <span>{{ n.time | date: 'short' }}</span>
            </div>
          </div>
        </mat-list-item>
      </mat-list>

      <p *ngIf="notifications && notifications.length == 0" class="m-1 p-2 text-muted">
        {{ "NoNotificationsFound" | translate }} 
      </p>
    </mat-nav-list>
  </div>
</div>