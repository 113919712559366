import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { GuestLayoutComponent } from "./shared/components/layouts/guest-layout/guest-layout.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { Rolesconfig } from "Rolesconfig";
import { UserRoleGuard } from "./shared/guards/user-role.guard";
import { environment } from "@environments/environment";
export const rootRouterConfig: Routes = [
  {
    path: "",
    component: GuestLayoutComponent,
    loadChildren: () =>
      import("./views/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "home",
    component: GuestLayoutComponent,
    loadChildren: () =>
      import("./views/home/home.module").then((m) => m.HomeModule),
    data: { title: environment.appTitle, roles: Rolesconfig.authRoles.guest,breadcrumb: 'HOME' },
  },
  {
    path: "map",
    component: GuestLayoutComponent,
    loadChildren: () =>
      import("./views/map/AppMap.module").then((m) => m.AppMapmodule),
    data: { title: environment.appTitle, roles: Rolesconfig.authRoles.guest ,breadcrumb: 'MAP'},
  },
  // {
  //   path: "Buyerquotation",
  //   component: AdminLayoutComponent,
  //   loadChildren: () =>
  //     import("./views/Farmezo/quotationForBuyer/quotationForBuyer.module").then(
  //       (m) => m.quotationForBuyerModule
  //     ),
  //   data: { title: environment.appTitle, roles: Rolesconfig.authRoles.guest,breadcrumb: 'BUYERQUOTATION' },
  // },
  // {
  //   path: "sellerquotation",
  //   component: AdminLayoutComponent,
  //   loadChildren: () =>
  //     import(
  //       "./views/Farmezo/quotationForSeller/quotationForSeller.module"
  //     ).then((m) => m.quotationForSellerModule),
  //   data: { title: environment.appTitle, roles: Rolesconfig.authRoles.guest ,breadcrumb: 'SELLERQUOTATION'},
  // },
  // {
  //   path: "quotations",
  //   component: GuestLayoutComponent,
  //   loadChildren: () =>
  //     import(
  //       "./views/Farmezo/quotationForSeller/quotationForSeller.module"
  //     ).then((m) => m.quotationForSellerModule),
  //   data: { title: environment.appTitle, roles: Rolesconfig.authRoles.guest,breadcrumb: 'QUOTATIONS' },
  // },

  // {
  //   path: "findquotation",
  //   component: AdminLayoutComponent,
  //   loadChildren: () =>
  //     import(
  //       "./views/Farmezo/quotationForSeller/quotationForSeller.module"
  //     ).then((m) => m.quotationForSellerModule),
  //   data: { title: environment.appTitle, roles: Rolesconfig.authRoles.guest,breadcrumb: 'FINDQUOTATION' },
  // },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "person",
        loadChildren: () =>
          import("./views/Person/Person.module").then(
            (m) => m.PersonModule
          ),
        data: {
          title: "Person",
          breadcrumb: "PERSON",
          roles: Rolesconfig.authRoles.user,
        },
      },
    ],

  }
  // ,{
  //   path: "",
  //   component: AdminLayoutComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: "ticket",
  //       loadChildren: () =>
  //         import("./views/Ticket/Ticket.module").then(
  //           (m) => m.TicketModule
  //         ),
  //       data: {
  //         title: "Ticket",
  //         breadcrumb: "TICKET",
  //         roles: Rolesconfig.authRoles.user,
  //       },
  //     },
  //   ],

  // }
  
  ,{
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "payment",
        loadChildren: () =>
          import("./views/Payment/Payment.module").then(
            (m) => m.PaymentModule
          ),
        data: {
          title: "Payment",
          breadcrumb: "PAYMENT",
          roles: Rolesconfig.authRoles.sa,
        },
      },
    ],

  },

  {
    path: "",
    component: GuestLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () =>
          import("./views/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: " Farmenzo", roles: Rolesconfig.authRoles.guest },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: {
          title: "Dashboard",
          breadcrumb: "DASHBOARD",
          roles: Rolesconfig.authRoles.user,
        },
      },
    ],
  },
 
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "chat",
        loadChildren: () =>
          import("app/views/app-chats/app-chats.module").then(
            (m) => m.AppChatsModule
          ),
        data: {
          title: "CHAT",
          breadcrumb: "CHAT",
          roles: Rolesconfig.authRoles.user,
        },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: "farmsManager",
        loadChildren: () =>
          import("./views/Farmezo/farm/farm.module").then((m) => m.FarmModule),
        data: {
          title: "store",
         // breadcrumb: "FARM",
          roles: Rolesconfig.authRoles.guest,
        },
      },
      {
        path: "farm/userfarms/:farmId",
        loadChildren: () =>
          import("./views/Farmezo/FarmProduct/FarmProduct.module").then(
            (m) => m.FarmProductModule
          ),
        data: {
          title: "products",
          breadcrumb: "PRODUCTS",
          roles: Rolesconfig.authRoles.user,
        },
      },
      {
        path: "farm/:farmId/brandManager",
       // component: FavoritefarmsComponent,
       // canActivate: [UserRoleGuard],
        children: [
        {
          path: "",
          loadChildren: () =>
            import("./views/Farmezo/Brand/brand.module").then((m) => m.BrandModule),
          data: {
            title: "brand",
           breadcrumb: "BRAND",
            roles: Rolesconfig.authRoles.sa,
          },
        },
      ]
      },
      {
        path: "farm/userfarms/:farmId/product/:productId/farmProduct/:farmproductId",
        loadChildren: () =>
          import(
            "./views/Farmezo/FarmProductQuantity/FarmProductQuantity.module"
          ).then((m) => m.FarmProductQuantityModule),
        data: {
          title: "product Quantities",
          breadcrumb: "PRODUCT QUANITITIES",
          roles: Rolesconfig.authRoles.guest,
        },
      },
      {
        path: "specialOffer",
        loadChildren: () =>
          import(
            "./views/Farmezo/special-offer/special-offer.module"
          ).then((m) => m.SpecialOfferModule),
        data: {
          title: "Special Offer",
          breadcrumb: "SPECIAL OFFER",
          roles: Rolesconfig.authRoles.guest,
        },
      },
      
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "OrdersManager",
        loadChildren: () =>
          import("./views/Farmezo/Order/Order.module").then(
            (m) => m.OrderModule
          ),
        data: {
          title: "order",
          breadcrumb: "ORDER",
          roles: Rolesconfig.authRoles.user,
        },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "Profile",
        loadChildren: () =>
          import("./views/Profile/Profile.module").then(
            (m) => m.ProfileModule
          ),
        data: {
          title: "Profile",
          breadcrumb: "PROFILE",
          roles: Rolesconfig.authRoles.user,
        },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
