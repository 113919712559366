import { Component, OnInit, AfterViewInit, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from "@angular/router";

import { RoutePartsService } from "./shared/services/route-parts.service";
// import { ThemeService } from './shared/services/theme.service';

import { filter } from "rxjs/operators";
import { globalConfigService } from "environments/environment.service";
import { environment } from "environments/environment";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
//import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
// import { LayoutService } from './shared/services/layout.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = environment.appTitle;
  pageTitle = "";

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,


   // private glb: globalConfigService
  ) {

  }

  ngOnInit() {
    
    // this.glb.load().subscribe(
    //   (response: any) => {

    //     environment.apiURL = response.apiURL;
    //   },
    //   (err) => {

    //     console.log(err);
    //   }
    // );
    this.changePageTitle();
    //this.addCutomIcon()
  }
  addCutomIcon(){
    this.matIconRegistry.addSvgIcon(
      `Submitorder`,
     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/Submitfile.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `waitingListorder`,
     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/waitingclipboard_525760.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `orderaccepted`,
     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/accepted_2567986.svg")
    );
    
    this.matIconRegistry.addSvgIcon(
      `orderapproval`,
     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/approval.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `orderCancelled`,     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/noun_reject_1800792.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `orderReject`,     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/noun_Sad_645780.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `orderpackaged`,
     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/package.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `ordertruck`,     
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/customIcon/orderStatus/truck.svg")
    );
    
 
  }
  ngAfterViewInit() {}
  changePageTitle() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeChange) => {
        var routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        if (!routeParts.length) return this.title.setTitle(this.appTitle);
        // Extract title from parts;
        this.pageTitle = routeParts
          .reverse()
          .map((part) => part.title)
          .reduce((partA, partI) => {
            return `${partA} > ${partI}`;
          });
        this.pageTitle += ` | ${this.appTitle}`;
        this.title.setTitle(this.pageTitle);
      });
  }
}
