import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotificationService } from "app/shared/services/notification.service";
import { SignalRNotificationService } from "app/shared/services/SignalRNotification.service";
import { SignalRNotificationObserverManagerService } from "app/shared/services/SignalRNotificationObserverManagerService";

@Component({
  selector: "app-chatNotifications-warning",
  templateUrl: "./chat-notifications-warning.component.html",
  styleUrls: ["./chat-notifications-warning.component.scss"],
})
export class chatNotificationsWarningComponent implements OnInit {
  @Input() chatNotificPanel;
  count: number = 0;
  @Output() showChatNotificationdetailsEmitter: EventEmitter<boolean> =
    new EventEmitter();
  constructor(
    public signalRNotificationService: SignalRNotificationService,
    private SignalRObserver: SignalRNotificationObserverManagerService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.setupSingalR();
    this.GetChatNotificationsCount();
  }
  GetChatNotificationsCount() {
    this.notificationService.GetChatNotificationsCount().subscribe((p) => {
      this.count = p.data;
    });
  }
  setupSingalR() {
    this.checkConnectionStatus();
  }
  checkConnectionStatus() {
    // const isConnected = this.signalRNotificationService.isConnected;
    // this.signalRNotificationService.manageConnection();
    this.SignalRObserver.iNeedConnection();

    this.SignalRObserver.connectionStartedObserver$.subscribe(
      (hubConnection) => {
        this.addTransferNotificationDataCountListener(hubConnection);
      }
    );
    // this.signalRNotificationService
    //   .manageConnection()
    //   .then(() => {
    //     //this.isConnected = true;
    //     console.log("Connection started");
    //     this.addTransferNotificationDataCountListener();
    //   })
    //   .catch((err) => {
    //     // this.isConnected = false;
    //     console.log("Error while starting connection: " + err);
    //   });
  }
  toggleNotific() {
    this.showChatNotificationdetailsEmitter.emit(true);
    this.chatNotificPanel.toggle();
  }

  public addTransferNotificationDataCountListener = (hubConnection) => {
    let _this = this;

    if(hubConnection ==undefined){
      this.signalRNotificationService.startConnection()
    }
    hubConnection?.on("chatnotificationsCount", (res) => {
      _this.count = res as number;
    });
  };
}
