<div class="app-admin-wrap" [dir]="layoutConf?.dir">
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->

  <app-header-top
    *ngIf="notificationPanel"
    class="mat-bg-primary mat-color-primary"
    [notificPanel]="notificationPanel" (showChatNotificationdetailsEmitter)="GetshowChatNotificationdetails($event)"
  >
  </app-header-top>
 
  <!-- Main Container -->
  <mat-sidenav-container
    [dir]="layoutConf.dir"
    class="app-admin-container full-width app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
    [ngClass]="adminContainerClasses"
    style="width: 100%"
  >
    <mat-sidenav-content>
      <!-- Top navigation layout (navigation for mobile screen) -->
      <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
      <app-sidebar-top *ngIf="  layoutConf.isMobile"></app-sidebar-top>

      <!-- App content -->
      <div
        class="main-content-wrap"
        id="main-content-wrap"
        style="width: 100%"
        [perfectScrollbar]=""
        [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar"
      >
        <!-- Header for side navigation layout -->

        <div
          class="rightside-content-hold"
          id="rightside-content-hold"
          [perfectScrollbar]="scrollConfig"
          [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar"
        >
          <!-- View Loader -->
          <div
            class="view-loader"
            *ngIf="isModuleLoading"
            style="position: fixed"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div class="spinner">
              <div class="double-bounce1 mat-bg-accent"></div>
              <div class="double-bounce2 mat-bg-primary"></div>
            </div>
          </div>
          <!-- Breadcrumb -->
          <!-- Disable breadcrumb -->
          <!-- <app-breadcrumb *ngIf="" ></app-breadcrumb> -->
          <!-- View outlet -->
          <div
            class="container-dynamic"
            style="width: 100%; height: 100%; position: absolute"
          >
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
      <div
        class="sidebar-backdrop"
        [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
        (click)="closeSidebar()"
      ></div>
    </mat-sidenav-content>

    <!-- Notificaation bar -->
    <!-- <mat-sidenav #notificationPanel mode="over" class=""  -->
    <mat-sidenav #notificationPanel mode="over" class="" position="end"
    
    (backdropClick)="closeNotifications(notificationPanel)">
  <div class="nofication-panel" fxLayout="column">
      <div  class="notification-header mat-bg-primary pb-1">
          <h6 class="m-0">{{ "Notifications" | translate }}</h6>
          <button
      mat-icon-button
      class="close-btn"
      aria-label="Close"
      (click)="closeNotifications(notificationPanel)"
    >
      <mat-icon>close</mat-icon>
    </button>
        </div>
        
    
    <app-notificationsDetails
      *ngIf="notificationPanel.opened"
      [notificPanel]="notificationPanel"
    ></app-notificationsDetails>
  </div>

</mat-sidenav>

  
  </mat-sidenav-container>
</div>
