 //import { config } from "config";

import { SEARCH_BY } from "app/shared/models/farm.model";

 
export const environment = {
  production: true,
  appTitle:'vatrina view',
    //apiURL: "https://192.168.100.39:7500/server/api/",  
    //apiURL: "https://localhost:443/server/api/",
   // apiURL: "https://68.221.169.205/api/",
    apiURL: "https://backend.vatrinaview.com/api/",
    loggedin: false,
    mapDefaultProjection: "EPSG:3355",
    dummyImage: "./assets/images/farmenzo/Dummy.png",
    dummyFaceImage: "./assets/images/farmenzo/face-8.png",  
   productDummyImage:"./assets/images/farmenzo/productDummy.png",
    PaganatiorPageSize: 10,
    //farmslayer: "http://localhost:8086/geoserver/cite/ows",
    // farmslayer: "https://68.221.169.205/geoserver/cite/ows",
    farmslayer: "https://backend.vatrinaview.com/geoserver/cite/ows",
    MaxnumberUserFarms: 1,
    productCategoryMaxdepth: 1,
    googleClinetID:
      "299821104999-69scgl4b3il9jqb1bupic4b35uki9f0q.apps.googleusercontent.com",

      
    facebookClinetId: "955589018593882",
    // hubUrl:"http://localhost:44365/",
    //hubUrl: "https://192.168.100.39:7500/server",
    // hubUrl: "https://68.221.169.205/",
    hubUrl: "https://backend.vatrinaview.com/",
    ismobileApp:false,
    SearhByDefault: SEARCH_BY.FARMS,
    RatestarColor:"accent",
    RatestarCount:5,
    MapCenterlatitude :3487830.877218816,
    MapCenterlongitude :3495528.8311001775,
    SearchFarmProductNameLayer:"SearchFarmProduct",
    uploaderImageSize:51322880,  //5M => 5*1024*1024
    allowedFileType:['image/png', 'image/jpeg'],
    contactInfo:{
      phoneNumber:+201013661697,
      whatsapp:{
        sendto:+201013661697,   
      },
      email:{
        Subject:"Inquiry",
        sendto:"info@nheroz.com",    
      }
    }
};
