import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { SafeUrl } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "@environments/environment";
import { scrolllerDirection, SetPaginationAfterResponse, SetPaginationBeforeSendRequest } from "app/shared/helpers/scrolller";

import { NotificationDto } from "app/shared/models/notificationDto.model";
import {
  PaginationFilter,
  PaginationResult,
  ResultResponse,
} from "app/shared/models/utils.model";
import { NotificationService } from "app/shared/services/notification.service";
import { SignalRNotificationService } from "app/shared/services/SignalRNotification.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-notificationsDetails",
  styleUrls: ["./notificationsDetails.component.scss"],
  templateUrl: "./notificationsDetailsCard.component.html",
})
export class notificationsDetailsComponent implements OnInit {
  @Input() notificPanel;

  // Dummy notifications
  notifications: Notification[] = [];
  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 100;
  direction = "";
  shareUrl: string = "";
  farmThumbnailUrl: SafeUrl;
  notificationPaginationResult: PaginationResult<NotificationDto[]>;
  constructor(
    private router: Router,
    public signalRNotificationService: SignalRNotificationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
    if (this.notificPanel.opened) {
      this.GetNotifications();
    }

    // this.setupSingalR();
  }
  setupSingalR() {
    // this.signalRService.startConnection();
    //  this.signalRNotificationService.addTransferNotificationDataListener();

    this.addTransferNotificationDataListener();
  }

  public addTransferNotificationDataListener = () => {
    this.signalRNotificationService.hubConnection?.on(
      "notifications",
      (res) => {
        this.fillNotification(res.data);
      }
    );
  };
  // private GetNotificationsAsync = () => {
  //   this.notificationService.GetNotifications().subscribe((res) => {
  //     this.fillNotification(res.data);
  //     console.log(res);
  //   });
  // };
  fillNotification(data: NotificationDto[]) {
    data.forEach((row) => {
      var time = new Date().getTime() - new Date(row.createdOn).getTime();
      let temp = {
        id: row.id,
        createdOn: row.createdOn,
        message: row.subject,
        icon: "card_giftcard",
        time: `${time} ago`,
        route: row.url != null ? `${row.url}` : row.url,
        color: "primary",
        isReaded: row.isReaded,
        senderName: row.sender.Name,
        senderimg: row.sender.imageURL,
      };
      this.notifications.push(temp);
    });
  }
  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }

  onClick(notification: Notification) {
    if (!notification) {
      return;
    }
   
 
    if (notification.isReaded && notification.route) {
      //this.router.navigateByUrl(notification.route);
      this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([notification.route]));
      return;
    }

    this.RouteTo(notification);
  }
  RouteTo(notification: Notification) {
    this.notificationService
      .setNotificationIsReaded(notification.id)
      .subscribe((result) => {
        if (notification.route) {
         // this.router.navigateByUrl(notification.route);
          this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate([notification.route]));
        }
      });
  }
  GetNotifications() {
    this.GetNotificationsdirectAsync(scrolllerDirection.ScrollDawen);
  }

  onScroll(direction:scrolllerDirection) {
    this.GetNotificationsdirectAsync(direction);
  }

  onScrollDown() {
    this.GetNotificationsdirectAsync(scrolllerDirection.ScrollDawen);

    this.direction = "down";
  }

  onUp() {
    this.GetNotificationsdirectAsync(scrolllerDirection.ScrollUp);

    this.direction = "up";
  }
  closeNotifications(){
   // this.dialogRef.close();

  }
  GetNotificationsdirectAsync(direction:scrolllerDirection) {
    // this.cdr.detectChanges();
    // this.resourcesLoaded = false;

    //this.isLoadingResults = true;
    // return this.exampleDatabase!.getRepoIssues(
    //   this.sort?.active, this.sort?.direction, this.paginator.pageIndex);

    // const paginationFilter: PaginationFilter = {
    //   OrderBy: null,
    //   PageSize: environment.PaganatiorPageSize,
    //   PageNumber:
    //     this.notificationPaginationResult != undefined
    //       ? isScrolledForward == true
    //         ? this.notificationPaginationResult.pageNumber + 1
    //         : this.notificationPaginationResult.pageNumber == 0
    //         ? 0
    //         : this.notificationPaginationResult.pageNumber - 1
    //       : 0,
    //   nextUrl: null,
    // };

    let paginationFilter: PaginationFilter;
    paginationFilter =SetPaginationBeforeSendRequest(this.notificationPaginationResult,direction,null);
    if(paginationFilter ==undefined){
      return;
    }

    this.notificationService
      .GetNotifications(paginationFilter)
      .subscribe((res) => {
        // this.notificationPaginationResult = res as PaginationResult<
        //   NotificationDto[]
        // >;
        let newPagination=res as PaginationResult<NotificationDto[]>;
        this.notificationPaginationResult = SetPaginationAfterResponse(newPagination,this.notificationPaginationResult);

        this.fillNotification(res.data);
        console.log(res);
      });

    console.log(this.notificationPaginationResult);
  }
  sharefarmURL(row) {
    return `${this.shareUrl}/farmsManager/farmprofile/${row.id}`;
  }
  toFarm(row) {
   // this.router.navigateByUrl(`/farmsManager/farmprofile/${row.id}`);
    this.router
    .navigateByUrl("/", { skipLocationChange: true })
    .then(() => this.router.navigate([`/farmsManager/farmprofile/${row.id}`]));
  }
}
export class Notification {
  id: string;
  message: string;
  icon: string;
  time: string;
  route: string;
  color: string;
  isReaded: boolean;
}
