import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotificationService } from "app/shared/services/notification.service";
import { SignalRNotificationService } from "app/shared/services/SignalRNotification.service";
import { SignalRNotificationObserverManagerService } from "app/shared/services/SignalRNotificationObserverManagerService";

@Component({
  selector: "app-notifications-warning",
  templateUrl: "./notifications-warning.component.html",
  styleUrls: ["./notifications-warning.component.scss"],
})
export class NotificationsWarningComponent implements OnInit {
  @Input() notificPanel;
  @Output() showChatNotificationdetailsEmitter: EventEmitter<boolean> =
    new EventEmitter();
  count: number = 0;

  constructor(
    public signalRNotificationService: SignalRNotificationService,
    private SignalRObserver: SignalRNotificationObserverManagerService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.setupSingalR();
    this.GetNotificationsCount();
  }
  GetNotificationsCount() {
    this.notificationService.GetNotificationsCount().subscribe((p) => {
      this.count = p.data;
    });
  }
  setupSingalR() {
    this.checkConnectionStatus();
    // this.addTransferNotificationDataCountListener();
  }
  checkConnectionStatus() {
    // const isConnected = this.signalRNotificationService.isConnected;
    this.SignalRObserver.iNeedConnection();
    this.SignalRObserver.connectionStartedObserver$.subscribe(
      (hubConnection) => {
        this.addTransferNotificationDataCountListener(hubConnection);
      }
    );
    // this.signalRNotificationService.manageConnection()
    // .then(() => {
    //   //this.isConnected = true;
    //   console.log("Connection started");
    //   this.addTransferNotificationDataCountListener();
    // })
    // .catch((err) => {
    //   // this.isConnected = false;
    //   console.log("Error while starting connection: " + err);
    // });
  }
  toggleNotific() {
    this.showChatNotificationdetailsEmitter.emit(false);
    this.notificPanel.toggle();
  }

  public addTransferNotificationDataCountListener = (hubConnection) => {
    let _this = this;
    if (hubConnection == undefined) {
      this.signalRNotificationService.startConnection();
    }
    hubConnection?.on("notificationsCount", (res) => {
      _this.count = res;
    });
  };
}
