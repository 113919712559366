import { Injectable, Inject, Renderer2, RendererFactory2, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import  { getQueryParam } from '../helpers/url.helper';
 

export interface ITheme {
  name: string,
  baseColor?: string,
  isActive?: boolean,
  sidebarColor?: string,
  topbarColor?: string,
  footerColor?: string,

  matTheme:string
  
}

@Injectable()
export class ThemeService {
  public onThemeChange :EventEmitter<ITheme> = new EventEmitter();

  public egretThemes :ITheme[]  = [
    {
      "matTheme": "egret-dark-gery",
      "name": "egret-dark-gery",
      "baseColor": "#1a1919ca",
      "isActive": false,
      'sidebarColor': "black",
      'topbarColor': "black",
      'footerColor': "black"
    },
    {
      "matTheme": "egret-dark-purple",
    "name": "egret-dark-purple",
    "baseColor": "purple",
    "isActive": false,
    'sidebarColor': "purple",
    'topbarColor': "purple",
    'footerColor': "purple"
  }, {
    "matTheme":"egret-dark-pink",
    "name": "egret-dark-pink",
    "baseColor": "#e91e63",
    "isActive": false,
    'sidebarColor': "pink",
    'topbarColor': "pink",
    'footerColor': "pink"
  }, {
    "matTheme":"egret-blue",
    "name": "egret-blue",
    "baseColor": "blue",
    "isActive": false,
    'sidebarColor': "blue",
    'topbarColor': "blue",
    'footerColor': "blue"
  }, {
    "matTheme":"egret-light-purple",
    "name": "egret-light-purple",
    "baseColor": "#1a2038",   
    "isActive": false,
    'sidebarColor': "light-purple",
    'topbarColor': "light-purple",
    'footerColor': "light-purple"
  }, {
    "matTheme":"egret-navy",
    "name": "egret-navy",
    "baseColor": "#1a2038",
    "isActive": false,
    'sidebarColor': "navy",
    'topbarColor': "navy",
    'footerColor': "navy" 
  },
  {
    "matTheme": "egret-green",
    "name": "egret-green",
    "baseColor": "#1a1919ca",
    "isActive": false,
    'sidebarColor': "green",
    'topbarColor': "green",
    'footerColor': "green"
  },
  {
    "matTheme": "egret-vatrina-viewer",
    "name": "egret-vatrina-viewer",
    "baseColor": "#415A77",
    "isActive": false,
    'sidebarColor':"teal",// "#1B263B",
    'topbarColor': "#1B263B",
    'footerColor':"teal" // "#1B263B"
  },
  {
    "matTheme": "egret-vatrina-viewer-v2",
    "name": "egret-vatrina-viewer-v2",
    "baseColor": "#009688", // Teal 500 - the primary base color for your theme
    "isActive": true,
    "sidebarColor":"white", //"#004d40", // Teal 900 - a deep, rich teal for the sidebar
    "topbarColor": "#00796b", // Teal 700 - slightly lighter than the sidebar for contrast
    "footerColor":"tealdarker" //"#004d40" // Teal 900 - matches the sidebar for a balanced layout
  
  }
  
];



  public activatedTheme: ITheme;
  private renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private document: Document,
  
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // Invoked in AppComponent and apply 'activatedTheme' on startup
  applyMatTheme( themeName: string) {

    
   
      
    this.activatedTheme = this.egretThemes.find(t => t.name === themeName); 
    this.flipActiveFlag(themeName);

    
    // *********** ONLY FOR DEMO **********
    this.setThemeFromQuery();
    // ************************************

    // this.changeTheme(themeName);
    this.renderer.addClass(this.document.body, themeName);

  }

  changeTheme(prevTheme, themeName: string) {
  
    this.renderer.removeClass(this.document.body, prevTheme);
    this.renderer.addClass(this.document.body, themeName);
    this.flipActiveFlag(themeName);
    this.onThemeChange.emit(this.activatedTheme);
  }

  flipActiveFlag(themeName:string) {
   
    this.egretThemes.forEach((t) => {
      t.isActive = false;
      if(t.name === themeName) {
        t.isActive = true;
        this.activatedTheme = t;
      }
    });
  }

  // *********** ONLY FOR DEMO **********
  setThemeFromQuery() {
   
    let themeStr = getQueryParam('theme');
    try {
      this.activatedTheme = JSON.parse(themeStr);
      console.log(this.activatedTheme);
      
      this.flipActiveFlag(this.activatedTheme.name);
    } catch(e) {}
  }
}
