import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from "@angular/core";
import { ThemeService } from "../../services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { TranslateService } from "@ngx-translate/core";
import { JwtAuthService } from "../../services/auth/jwt-auth.service";
import { CookieService } from "ngx-cookie-service";
import { IUser } from "app/shared/models/user.model";
import { DomSanitizer } from "@angular/platform-browser";
import { FileService } from "app/shared/services/File.service";
import { environment } from "@environments/environment";

@Component({
  selector: "app-header-side",
  templateUrl: "./header-side.template.html",
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @Input() chatNotificationPanel;
  @Output() showChatNotificationdetailsEmitter: EventEmitter<boolean> =
    new EventEmitter();

  user: IUser = {} as IUser;
  public availableLangs = [
    {
      name: "English",
      code: "en",
      flag: "flag-icon-us",
    },
    {
      name: "ES",
      code: "es",
      flag: "flag-icon-es",
    },
    {
      name: "عربي",
      code: "ar",
      flag: "flag-icon-kw",
    },
  ];
  currentLang = this.availableLangs[0];

  public egretThemes;
  public layoutConf: any;
  userProfileImgURL: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
    private fileService: FileService
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;

    if (this.cookieService.check("lang")) {
      this.availableLangs.forEach((lan) => {
        if (this.cookieService.get("lang") == lan.code) this.currentLang = lan;
      });
    }

   // this.translate.use(this.currentLang.code);
    this.getUserInfo();
  }

  getUserInfo() {
    ;
    if (this.jwtAuth.ls.getItem("Farmenzo_APP_USER")) {
      this.user = this.jwtAuth.ls.getItem("Farmenzo_APP_USER");
      this.getlogedInUserphoto(this.user.profileImageUrl);
    }
  }
  getlogedInUserphoto(profileImageUrl){

    if (
      profileImageUrl != "" &&
      profileImageUrl != undefined &&
      profileImageUrl != null
    ) {
      this.fileService.GetFileBy(profileImageUrl,'profile').subscribe((file) => {
        this.userProfileImgURL =file;
      //  let objectURL = URL.createObjectURL(file);
       // this.userProfileImgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    }else{
      this.userProfileImgURL = environment.dummyFaceImage;
    }
  
    
  
  }
 

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === "compact") {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: "full",
          sidebarCompactToggle: false,
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: "compact",
        sidebarCompactToggle: true,
      },
      { transitionClass: true }
    );
  }

  onSearch(e) {
    //   console.log(e)
  }
  GetshowChatNotificationdetails($event: boolean) {
    this.showChatNotificationdetailsEmitter.emit($event);
  }
}
