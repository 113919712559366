import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Renderer2,
  Output,
  EventEmitter,
} from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { Subscription, from } from "rxjs";
import { ThemeService } from "../../../shared/services/theme.service";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../../services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { MatDialog } from "@angular/material/dialog";

import { DefaultMenu_notLogin } from "app/shared/models/menu.model";
import { IUser, initialUserState } from "app/shared/models/user.model";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "environments/environment";
import { ProfileService } from "app/shared/services/Profile/profile.service";
import { systemLanguage } from "app/shared/helpers/languares";
import { DomSanitizer } from "@angular/platform-browser";
import { FileService } from "app/shared/services/File.service";

@Component({
  selector: "app-header-top",
  templateUrl: "./header-top.component.html",
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  public userInfo: IUser = initialUserState;
  user: IUser = {} as IUser;
  public availableLangs = systemLanguage;
  currentLang = this.availableLangs[0];
  @Input() notificPanel;
  @Input() chatNotificationPanel;
  @Output() showChatNotificationdetailsEmitter: EventEmitter<boolean> =
    new EventEmitter();
  public isLogged: boolean = false;
  userProfileImgURL: any;

  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public jwtAuth: JwtAuthService,
    private profileService: ProfileService,
    private router: Router,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
    private fileService: FileService
  ) {}

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    
    // this.navService.language.next(
    //   this.jwtAuth.ls.getItem("Farmenzo_APP_USER").menuItems
    // );

    // if (this.cookieService.check("lang")) {

    // }
    this.translate.use('ar'); // Use Arabic language
    this.menuItemSub = this.navService.menuItems$.subscribe((res) => {
      
      if (!res) {
        return;
        // return this.router.navigateByUrl("sessions/signin");
      }

      res = res.filter(
        (item) =>
          item.type !== "icon" &&
          item.type !== "separator" &&
          item.activeFor != "sidebar"
      );
      let limit = 6;
      let mainItems: any[] = res.slice(0, limit);
      this.jwtAuth.user = this.jwtAuth.ls.getItem("Farmenzo_APP_USER");
      this.userInfo = this.jwtAuth.user;
      if (res.length <= limit) {
        return (this.menuItems = mainItems);
      }
      let subItems: any[] = res.slice(limit, res.length - 1);
      mainItems.push({
        name: "More",
        type: "dropDown",
        tooltip: "More",
        icon: "more_horiz",
        sub: subItems,
      });
      this.menuItems = mainItems;
    });

    if (
      this.jwtAuth.ls.getItem("Farmenzo_APP_USER") != null ||
      this.jwtAuth.ls.getItem("Farmenzo_APP_USER") != undefined
    ) {
      this.navService.menuItems.next(
        this.jwtAuth.ls.getItem("Farmenzo_APP_USER").menuItems
      );
      this.isLogged = true;
    }

    var language = this.jwtAuth.ls.getItem("Farmenzo_APP_USER")?.language;
    if (language == undefined || language == null) {
      this.currentLang = this.availableLangs[0];
    } else {
      this.currentLang = this.availableLangs.find((p) => p.id == language);
    }

 
   
    this.getUserInfo();
  }
  getUserInfo() {
    ;
    if (this.jwtAuth.ls.getItem("Farmenzo_APP_USER")) {
      this.user = this.jwtAuth.ls.getItem("Farmenzo_APP_USER");
      this.getlogedInUserphoto(this.user.profileImageUrl);
      //this.autoSignIn();
    }
  }
  getlogedInUserphoto(profileImageUrl){

    if (
      profileImageUrl != "" &&
      profileImageUrl != undefined &&
      profileImageUrl != null
    ) {
      this.fileService.GetFileBy(profileImageUrl,'profile').subscribe((file) => {
        
        this.userProfileImgURL=file;
       // let objectURL = URL.createObjectURL(file);
        //this.userProfileImgURL = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    }else{
      this.userProfileImgURL = environment.dummyFaceImage;
    }
  
    
  
  }
  signout() {
    this.navService.menuItems.next([]);

    this.isLogged = false;
    this.jwtAuth.signout();
  }
  getRouteURL(url) {
    //environment.apiURL
    this.router.navigate([url]);
    return url;
  }
  autoSignIn() {
    this.jwtAuth.AutoSigninByToken().subscribe(
      (response) => {
        if (response) {
          this.navService.menuItems.next(
            this.jwtAuth.ls.getItem("Farmenzo_APP_USER").menuItems
          );
          this.isLogged = true;
          this.jwtAuth.SetTranslate();
    
        }
      },
      (err) => {}
    );
  }

  openSignIN() {
   // this.router.navigateByUrl("sessions/signin");
    this.router
    .navigateByUrl("/", { skipLocationChange: true })
    .then(() => this.router.navigate(["sessions/signin"]));
  }

  openSignUp() {
    //this.router.navigateByUrl("sessions/signup");
    this.router
    .navigateByUrl("/", { skipLocationChange: true })
    .then(() => this.router.navigate(["sessions/signup"]));
  }

  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }



  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }
  GetshowChatNotificationdetails($event: boolean) {
    this.showChatNotificationdetailsEmitter.emit($event);
  }
}
