<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Search form -->
  <!-- <div fxFlex fxHide.lt-sm="true" class="search-bar">
    <form class="top-search-form">
      <mat-icon role="img">{{"Search" |translate}}</mat-icon>
      <input autofocus="true" placeholder="Search" type="text" />
    </form>
  </div> -->

  <span fxFlex></span>
  <!-- Language Switcher -->



  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <app-chatNotifications-warning
    [chatNotificPanel]="chatNotificationPanel"
    (showChatNotificationdetailsEmitter)="GetshowChatNotificationdetails($event)"
  ></app-chatNotifications-warning>
  <app-notifications-warning
    [notificPanel]="notificPanel"
    (showChatNotificationdetailsEmitter)="GetshowChatNotificationdetails($event)"
  ></app-notifications-warning>
  <!-- Top left user menu -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
  >
   
    <img
    [src]="userProfileImgURL"
    class="mat-elevation-z1"
    alt=""
  />
  </button>
  <mat-menu #accountMenu="matMenu">
    <!-- /// theme switcher  -->

 

    <button mat-menu-item [routerLink]="['/Profile/UserProfile']">
      <mat-icon>account_circle</mat-icon>
      <span>{{user?.firstName}} {{user?.lastName}}</span>
    </button>
    <!-- End Switcher -->

    <button mat-menu-item [routerLink]="['/Profile/UserAddressList']">
      <!-- <button mat-menu-item routerLink="Profile/UserAddressList"> -->
      <mat-icon>pin_drop</mat-icon>

      <span>{{"myAddress" |translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/Profile/UserContactInfoList']">
      <mat-icon> perm_phone_msg</mat-icon>
      <span>{{"myContactInfo" |translate}}</span>
    </button>
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{"SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
