import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Rolesconfig } from "Rolesconfig";

@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private jwtAuth: JwtAuthService,
    private snack: MatSnackBar
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var user = this.jwtAuth.getUser();

    if (
      user &&
      route.data &&
      route.data.roles &&
      route.data.roles.includes(user.role)
    ) {
      return true;
    }
    // else if (
    //   route.data &&
    //   route.data.roles &&
    //   route.data.roles.includes(Rolesconfig.authRoles.guest)
    // ) {
    //   return true;
    // }
    else {
      if (this.ValidateGest(route)) {
        return true;
      } else {
        this.router.navigate(["/sessions/signin"], {
          queryParams: {
            return: state.url,
          },
        });
      }

      return false;
    }
  }
  private ValidateGest(route: ActivatedRouteSnapshot) {
    let isValid = false;
    if (
      route.data &&
      route.data.roles &&
      Rolesconfig.authRoles.guest.length > 0
    ) {
      Rolesconfig.authRoles.guest.forEach((element) => {
        if (route.data.roles.includes(element)) {
          isValid = true;
        }
      });
    }
    return isValid;
  }
}
