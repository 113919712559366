import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// SERVICES
import { ThemeService } from "./services/theme.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./guards/auth.guard";
import { UserRoleGuard } from "./guards/user-role.guard";
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { AppLoaderService } from "./services/app-loader/app-loader.service";
import { SharedComponentsModule } from "./components/shared-components.module";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { SharedDirectivesModule } from "./directives/shared-directives.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { SharedMaterialModule } from "./shared-material.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";


@NgModule({
  imports: [InfiniteScrollModule],
  providers: [
    ThemeService,
    RoutePartsService,
    AuthGuard,
    UserRoleGuard,
    AppConfirmService,
    AppLoaderService,    
  ],
  exports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    SharedDirectivesModule,
    SharedPipesModule,
    TranslateModule,
    SharedComponentsModule,
    InfiniteScrollModule,
  
  ],
})
export class SharedModule {}
