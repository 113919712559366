import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import "rxjs/add/operator/do";
import { Observable } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { environment } from "environments/environment";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtAuth: JwtAuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();

    var changedReq;
    
    if (
      token &&
      req.url.toLowerCase().indexOf(environment.apiURL.toLowerCase()) > -1
    ) {
      changedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      changedReq = req;
    }

    return next.handle(changedReq).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.jwtAuth.setUserAndToken(null, null, false);
            this.router.navigate(["/sessions/signin"]);
          }
        }
      }
    );
  }
}
